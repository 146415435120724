import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.section`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  ${down('md')} {
    padding: 64px 16px 0;
  }
`;

export const Banner = styled.div<{ bannerDesktop?: string; bannerMobile?: string }>`
  max-width: 1224px;
  width: 100%;
  padding: 266px 64px 64px 64px;
  margin: 96px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 16px;
  gap: 16px;
  background: linear-gradient(90deg, rgba(8, 51, 68, 0.8) 0%, rgba(8, 51, 68, 0) 100%),
    url(${({ bannerDesktop }) => bannerDesktop}), lightgray 0px -129.5px / 100% 138.071% no-repeat;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 14px 50px 0px rgba(5, 28, 37, 0.89);

  ${down('md')} {
    max-width: 768px;
    width: 100%;
    padding: 16px;
    margin: 0;
    gap: 0;
    background: linear-gradient(transparent 0 125px, #083344 330px 1000px),
      url(${({ bannerMobile }) => bannerMobile}) -168px -27px no-repeat;
    background-size: 177%;
    background-repeat: no-repeat;
    background-color: #083344;

    box-shadow: 0;
  }
`;

export const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-self: stretch;
  font-style: normal;
  margin: 0;
  font-family: Inter;
  line-height: 130%;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  max-width: 526px;

  ${down('md')} {
    font-size: 32px;
    line-height: 130%;
    padding: 258px 16px 0;
  }
`;

export const BannerSubHeader = styled.h2`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-self: stretch;
  font-style: normal;
  margin: 0 0 48px;
  font-family: Inter;
  line-height: 32px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  max-width: 526px;

  ${down('md')} {
    font-size: 16px;
    line-height: 25px;
    padding: 16px 16px 0;
    margin: 0 0 24px;
  }
`;

export const BannerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  ${down('xl')} {
    gap: 16px;
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 16px;
  }
  ${down('md')} {
    padding: 0;
  }
`;

export const BannerButton = styled.a`
  display: flex;
  gap: 9px;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #083344;
  transition: color 0.1s ease-in;

  &:hover {
    color: #0e7490;
  }

  img {
    vertical-align: middle;
  }

  position: relative;
  width: 256px;
  height: 73px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
  justify-content: center;

  ${down('md')} {
    padding: 24px 0;
    width: 100%;
  }
`;
