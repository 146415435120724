import { FC, memo, MouseEventHandler } from 'react';
import slugify from 'slugify';
import { Icons } from '@studenten/ui-components';
import * as types from 'api_entities/strapi/types';

import { Container, Inner, Title, List, ListItem } from './Summary.styles';

export type SummaryProps = {
  title: string;
  items: types.SummaryItem[];
};

const slugifyOptions = {
  lower: true,
  strict: true,
  trim: true,
};

const isBrowser = typeof window !== 'undefined';

const Summary: FC<SummaryProps> = ({ title, items }) => {
  const handleLinkClick = (elId: string): MouseEventHandler<HTMLAnchorElement> => (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isBrowser) {
      const element = document.getElementById(elId);

      if (element) {
        const top = element.offsetTop - 10;

        window.scrollTo({ top, behavior: 'smooth' });
      }
    }
  };

  return (
    <Container>
      <Inner>
        <Title>{title}</Title>
        <List>
          {items.map(({ id, title, headerText }) => {
            const elId = slugify(headerText, slugifyOptions);

            return (
              <ListItem key={id}>
                <a href={`#${elId}`} onClick={handleLinkClick(elId)}>
                  <Icons.Carret />
                  {title}
                </a>
              </ListItem>
            );
          })}
        </List>
      </Inner>
    </Container>
  );
};

export default memo(Summary);
