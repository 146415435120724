import { FC } from 'react';

import Header from 'features/layout/templates/Layout/components/Header';
import Footer from 'features/layout/templates/Layout/components/Footer';
import Breadcrumbs from 'features/other/components/Breadcrumbs';

import { Main } from './Layout.styled';

type LayoutProps = {
  showBreadcrumbs?: boolean;
};

export const Layout: FC<LayoutProps> = ({ children, showBreadcrumbs }) => {
  return (
    <>
      <Header />

      <Main id="main-layout">
        {showBreadcrumbs ? <Breadcrumbs /> : null}
        {children}
      </Main>
      <Footer />
    </>
  );
};
