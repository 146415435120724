import { FC, memo, ReactNode } from 'react';
import Image from 'next/image';
import Lottie from 'lottie-react';
import { formatLocalDate } from 'utils/helpers';

import { ContentWrapper } from 'src/common/ContentWrapper';
import * as types from 'api_entities/strapi/types';

import {
  Container,
  Content,
  HeaderWrapper,
  Title,
  Hero as StyledHero,
  SubTitle,
  LottieWrapper,
  PublishedAt,
  AuthorBy,
  BreadcrumbsWrapper,
  ImageWrapper,
} from './Hero.styles';

export type HeroProps = {
  title?: string;
  subtitle?: string;
  lottie?: Record<string, string>;
  dateOfPublication?: string;
  updatedAt?: string;
  readTime?: number;
  author?: types.Author;
  image?: types.MediaComponent;
  breadcrumbs?: ReactNode;
};

const Hero: FC<HeroProps> = ({
  title,
  subtitle,
  lottie,
  dateOfPublication,
  updatedAt,
  readTime,
  author,
  image,
  breadcrumbs,
}) => {
  const pubDate = dateOfPublication ? <time>{formatLocalDate(dateOfPublication)}</time> : '';
  const _readTime = readTime ? `${readTime} min leestijd` : '';
  const dateBlockContent = [pubDate, _readTime].filter(Boolean).join(' • ');
  const _author = author
    ? `door <span>${author.attributes.firstName} ${author.attributes.lastName}</span>`
    : '';
  const updated = updatedAt ? `Updated ${formatLocalDate(updatedAt)}` : '';
  const authorBy = [_author, updated].filter(Boolean).join(' • ');

  return (
    <Container as="section">
      <ContentWrapper>
        <StyledHero>
          {breadcrumbs ? <BreadcrumbsWrapper>{breadcrumbs}</BreadcrumbsWrapper> : null}
          <Content>
            <HeaderWrapper>
              <PublishedAt dangerouslySetInnerHTML={{ __html: dateBlockContent }}></PublishedAt>
              <Title>{title}</Title>
              {subtitle ? <SubTitle>{subtitle}</SubTitle> : null}
              <AuthorBy dangerouslySetInnerHTML={{ __html: authorBy }} />
            </HeaderWrapper>
            {lottie ? (
              <LottieWrapper>
                <Lottie loop animationData={lottie} />
              </LottieWrapper>
            ) : image ? (
              <ImageWrapper
                style={{ aspectRatio: `${image.attributes.width} / ${image.attributes.height}` }}
              >
                <Image
                  priority
                  width={image.attributes.width}
                  height={image.attributes.height}
                  src={image.attributes.url}
                  alt=""
                />
              </ImageWrapper>
            ) : null}
          </Content>
        </StyledHero>
      </ContentWrapper>
    </Container>
  );
};

export default memo(Hero);
