import { memo } from 'react';
import { NextPage } from 'next';

import * as types from 'api_entities/strapi/types';
import { StudentCard } from 'api_entities/student/types';
import { PillarPageCore } from '../PillarPageCore';
import { Specialisatie } from '../Specialisatie';
import { VacanciesAdSectionCards } from 'api_entities/strapi/types';

export type PillarPageProps = {
  strapiData: types.PillarPage;
  vacanciesAdSectionCards?: VacanciesAdSectionCards;
  data?: {
    data: StudentCard[];
    meta: { count: number };
  };
};

const PillarPage: NextPage<PillarPageProps> = ({ strapiData, data, vacanciesAdSectionCards }) => {
  if (!strapiData?.attributes?.templateType || strapiData?.attributes?.templateType === 'core') {
    return <PillarPageCore strapiData={strapiData} vacanciesAdSectionCards={vacanciesAdSectionCards} />;
  }
  if (strapiData?.attributes?.templateType === 'business') {
    return <Specialisatie strapiData={strapiData} data={data} />;
  }
  return null;
};

export default memo(PillarPage);
