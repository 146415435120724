import styled from 'styled-components';

import Breadcrumbs from './Breadcrumbs';

export const StyledBodyBreadcrumbs = styled(Breadcrumbs)`
  margin: 0 0 24px;

  li {
    display: inline;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.2;
    color: #232d5c;
  }

  & li + li::before {
    display: inline-block;
    margin: 0 10px;
    transform: rotate(15deg);
    border-right: 0.1em solid currentcolor;
    height: 0.8em;
    content: '';
  }

  a[aria-current='page'] {
    opacity: 0.4;
  }
`;

export const StyledHeroBreadcrumbs = styled(Breadcrumbs)`
  margin: 0;

  li {
    max-width: 290px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.2;

    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${({ theme }) => theme.brand.orange};
  }

  & li + li::before {
    display: inline-block;
    margin: 0 10px;
    transform: rotate(15deg);
    border-right: 0.1em solid currentcolor;
    height: 0.8em;
    content: '';
  }

  a[aria-current='page'] {
    color: ${({ theme }) => theme.system.white};
  }
`;
