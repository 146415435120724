

import { size } from 'constants/device';

import React, { useContext } from 'react';
import Link from 'next/link';
import BreadcrumbsContext from 'context/BreadcrumbsContext';
import { Breadcrumb } from 'context/BreadcrumbsContext/types';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';

import {
  StyledBreadCrumbs,
  BreadcrumbLink,
  BreadcrumbDivider,
  BreadcrumbCurrent,
} from './Breadcrumbs.styled';

const normalizeToWithSlash = (target?: string, params?: string) => {
  let result = target?.startsWith('/') ? target : `/${target}`
  if (params) {
    result += `?${params}`
  }  

  return result;
};

const generateToLink = (breadcrumbs: Breadcrumb[], finishIndex: number) => {
  let result = '';
  if (finishIndex === 0) {
    return '/';
  }
  breadcrumbs.forEach((breadcrumb, i) => {
    if (i <= finishIndex && i > 0) {
      result += normalizeToWithSlash(breadcrumb.to, breadcrumb.params);
    }
  });

  return result;
};

export const Breadcrumbs = () => {
  const { breadcrumbs } = useContext(BreadcrumbsContext);
  const { width } = useWindowSize();
  const isMobile = width < size.tablet;
  if (!breadcrumbs) return null;

  return (
    <StyledBreadCrumbs>
      {breadcrumbs.map((breadCrump, i) => {
        if (i <  breadcrumbs.length - 2 && isMobile) {
          return null;
        }
        if (i < breadcrumbs.length - 1) {
          return (
            breadCrump.to && (
              <>
                <Link key={breadCrump.to} href={generateToLink(breadcrumbs, i)} passHref>
                  <BreadcrumbLink>{breadCrump.name}</BreadcrumbLink>
                </Link>
                <BreadcrumbDivider>/</BreadcrumbDivider>
              </>
            )
          );
        }
        return <BreadcrumbCurrent key={breadCrump.to}>{breadCrump.name}</BreadcrumbCurrent>;
      })}
    </StyledBreadCrumbs>
  );
};
