import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { APP_ROUTES } from 'constants/routes';

import { BREADCRUMBS_MAP } from './constants';
import { Breadcrumb } from './types';

export const useBreadcrumbs = (customBreadcrumbs: Breadcrumb[]): Breadcrumb[] => {
  const router = useRouter();

  return useMemo(() => {
    const { asPath = '' } = router;
    let breadcrumbs: Breadcrumb[] = [];

    breadcrumbs.push(BREADCRUMBS_MAP[APP_ROUTES.HOME]);

    const indexOfQuery = asPath.indexOf('?');
    const normalizedAsPath = indexOfQuery >= 0 ? asPath.substring(0, indexOfQuery) : asPath;
    const pathArray = normalizedAsPath.split('/').filter((pathPart) => Boolean(pathPart.length));

    pathArray.forEach((pathPart) => {
      const breadcrumb = BREADCRUMBS_MAP[`/${pathPart}`];

      if (breadcrumb) {
        breadcrumbs.push(breadcrumb);
      }
    });

    breadcrumbs = breadcrumbs.concat(customBreadcrumbs);

    return breadcrumbs;
  }, [customBreadcrumbs, router?.asPath]);
};
