import { GetStaticPropsContext, GetServerSidePropsResult } from 'next';
import { ParsedUrlQuery } from 'querystring';

import { APP_ROUTES } from 'constants/routes';

import pillarPages from 'api_entities/strapi/pillarPages';

import { PillarPageProps } from 'src/templates/PillarPage/PillarPage';

import { PillarPage } from 'src/templates/PillarPage';

import { profileApi } from 'api_entities/student';
import { StudentCard } from 'api_entities/student/types';
import { getVacanciesAdSectionCards } from '../utils/helpers';

const DYNAMIC_KEY = 'dynamicPage';

const isHomePage = (params?: ParsedUrlQuery) => {
  const _params = Object.assign({}, params);

  return !_params[DYNAMIC_KEY]?.length;
};

const shouldBeRedirectToInhuren = (slug: string) =>
  slug.startsWith('personeel-') || slug.endsWith('-inhuren');

const parseDynamicPath = (path?: string | string[]): string => {
  if (typeof path === 'string') {
    return path;
  }

  const [slug] = path || [];

  if (!slug) {
    throw new Error('Slug not defined.');
  }

  return slug;
};

export default PillarPage;
async function fetchStudentsByCity(city: string) {
  try {
    // const city = 'Breda'; // Replace with the desired city
    const students: {
      data: StudentCard[];
      meta: { count: number };
    } = await profileApi.getStudentCardByCity(city);
    // Now, 'students' is typed as an array of 'Student' objects, providing type safety.
    return students;
  } catch (error) {
    console.error('Error fetching students by city:', error);
    return {
      data: [],
      meta: { count: 0 },
    };
  }
}
async function fetchStudentsByJob(job: string) {
  try {
    // const job = 'Zorg'; // Replace with the desired city
    const students: {
      data: StudentCard[];
      meta: { count: number };
    } = await profileApi.getStudentCardByJob(job);
    // Now, 'students' is typed as an array of 'Student' objects, providing type safety.
    return students;
  } catch (error) {
    console.error('Error fetching students by city:', error);
    return {
      data: [],
      meta: { count: 0 },
    };
  }
}

export const getServerSideProps = async (
  ctx: GetStaticPropsContext
): Promise<GetServerSidePropsResult<PillarPageProps>> => {
  const { params = {} } = ctx || {};

  if (isHomePage(params)) {
    return {
      redirect: {
        destination: APP_ROUTES.HOME,
        permanent: false,
      },
    };
  }
  const slug = parseDynamicPath(params[DYNAMIC_KEY]);

  try {
    const strapiData = await pillarPages.fetchBySlug(slug);
    if (
      strapiData?.attributes?.templateType === 'business' &&
      strapiData?.attributes?.category === 'city'
    ) {
      const data = await fetchStudentsByCity(strapiData?.attributes?.filter);
      return {
        props: {
          ...(strapiData && { strapiData }),
          ...(data && { data }),
        },
      };
    }
    if (
      strapiData?.attributes?.templateType === 'business' &&
      strapiData?.attributes?.category === 'job'
    ) {
      const data = await fetchStudentsByJob(strapiData?.attributes?.filter);
      return {
        props: {
          ...(strapiData && { strapiData }),
          data,
        },
      };
    }

    if (!strapiData) {
      return {
        notFound: true,
      };
    }

    const vacanciesAdSectionCards = await getVacanciesAdSectionCards(
      strapiData?.attributes?.vacanciesAdsSection
    );

    return {
      props: {
        ...(strapiData && { strapiData }),
        ...(vacanciesAdSectionCards && { vacanciesAdSectionCards }),
      },
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to render a dynamic page, params: ${JSON.stringify(ctx.params)}`, e);

    if (shouldBeRedirectToInhuren(slug)) {
      return {
        redirect: {
          destination: APP_ROUTES.WERKGEVER,
          permanent: false,
        },
      };
    }

    return {
      notFound: true,
    };
  }
};
