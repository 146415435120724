import { APP_ROUTES } from 'constants/routes';

import { Breadcrumb } from './types';

export const BREADCRUMBS_MAP: Record<string, Breadcrumb> = {
  [APP_ROUTES.HOME]: {
    href: APP_ROUTES.HOME,
    label: 'Startpagina',
  },
  [APP_ROUTES.BLOG]: {
    href: APP_ROUTES.BLOG,
    label: 'Blog',
  },
  [APP_ROUTES.CONTACTS]: {
    href: APP_ROUTES.CONTACTS,
    label: 'Contact Us',
  },
};
