import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 8px;

  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  background-color: ${({ theme }) => theme.system.white};
  color: ${({ theme }) => theme.system.black};

  ${up('md')} {
    position: sticky;
    position: -webkit-sticky;
    top: calc(${({ theme }) => theme.v2.dimensions.headerHeight} + 12px);
  }
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 16px;
`;

export const Title = styled.p`
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.3;
`;

export const List = styled.ul`
  margin: 12px 0;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const ListItem = styled.li`
  position: relative;
  margin-bottom: 24px;
  padding-left: 24px;

  svg {
    position: absolute;
    font-size: 20px;
    left: 0;
  }

  &:last-of-type {
    margin: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.brand.orange};
    cursor: pointer;
  }
`;
