import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.brand.blue};
`;

export const Hero = styled.div`
  position: relative;
  display: flex;
  padding: 32px 0;

  ${up('md')} {
    padding: 50px 0;
    min-height: 416px;
  }
`;

export const BreadcrumbsWrapper = styled.div`
  display: none;
  position: absolute;
  top: 30px;

  ${up('md')} {
    display: block;
  }
`;

export const PublishedAt = styled.p`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.25;
  text-transform: uppercase;
`;

export const AuthorBy = styled.p`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3;

  ${up('md')} {
    font-size: 1.4rem;
  }

  span, a {
    text-decoration: underline;
    font-weight: 400;

    ${up('md')} {
      text-transform: uppercase;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;

  ${up('md')} {
    flex-direction: row;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: ${({ theme }) => theme.system.white};

  ${up('md')} {
    padding: 30px 0;
    flex-grow: 1;
  }
`;

export const Title = styled.h1`
  margin: 0 0 18px;

  font-weight: 700;
  font-size: 2rem;
  line-height: 1.3;

  ${up('md')} {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 1.4;
  }
`;

export const SubTitle = styled.p`
  margin: 0 0 24px;

  line-height: 1.3;
  font-size: 1.6rem;

  ${up('md')} {
    font-size: 1.4rem;
  }
`;

export const ImageWrapper = styled.div`
  padding: 16px;
  margin: auto;
  max-height: 232px;

  ${up('md')} {
    padding: 0;
    max-width: 40%;
    max-height: 320px;
    margin-left: 24px;
  }
`;

export const LottieWrapper = styled.div`
  & > div {
    margin: 0 auto;
    aspect-ratio: 1 / 1;
    height: 232px;

    ${up('md')} {
      width: 100%;
      height: 320px;
    }
  }
`;
