import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export const ComponentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const GridWrapper = styled.div`
  max-width: 1224px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-style: normal;
  font-family: Inter;
  line-height: 130%;
  font-weight: 700;
  font-size: 48px;
  margin: 96px 0 32px;

  ${down('md')} {
    padding: 0 16px;
    font-size: 40px;
    font-weight: 700;
    line-height: 130%;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  gap: 16px;
  max-width: 1224px;
  margin: 0 auto;
  ${down('xl')} {
    grid-template-columns: repeat(3, minmax(288px, 1fr));
  }

  ${down('lg')} {
    grid-template-columns: repeat(2, minmax(288px, 1fr));
  }
  ${down('md')} {
    grid-template-columns: repeat(1, minmax(288px, 1fr));
  }
`;

export const Card = styled.a<{ imageUrl: string }>`
  text-decoration: none;
  width: 288px;
  height: 384px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;
  ${({ imageUrl }) => css`
    background: linear-gradient(180deg, rgba(8, 51, 68, 0) 40%, #083344 100%), url(${imageUrl}),
      lightgray 50% \;;
  `}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 14px 50px 0px rgba(5, 28, 37, 0.89);
`;
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 12px;
`;
export const CardTitle = styled.span`
  color: #ffffff;
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 130%;
`;
export const CardStatus = styled.span`
  color: #ffffff;
  font-size: 12px;
  font-family: Inter;
  line-height: 140%;
  display: flex;
  gap: 4px;
  justify-content: center;
`;
export const CardTags = styled.div`
  padding: 0 8px 8px;
  display: flex;
  gap: 4px;
`;
export const CardTag = styled.span`
  display: flex;
  height: 32px;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  width: fit-content;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  color: #ffffff;
  font-size: 12px;
  font-family: Inter;
  line-height: 140%;
`;

export const Button = styled.a`
  width: fit-content;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #1dbf70;
  transition: color 0.1s ease-in;
  position: relative;
  display: flex;
  padding: 9px 32px;
  border: 1px solid #1dbf70;
  border-radius: 8px;
  transition: border-color 0.1s ease-in;
  margin: 40px auto 73px;

  &:hover {
    border-color: #0891b2;
    color: #0891b2;
  }

  ${down('md')} {
    width: 100%;
    justify-content: center;
    margin: 40px auto 0;
  }
`;
