import { FC, memo } from 'react';

import { Frame930 } from './components/Frame930';
import { MetaTags } from 'src/common/Seo';
import { Services } from './components/Services';
import { FAQ } from './components/FAQ';
import { Testimonials } from './components/Testimonials';
import { PillarPageProps } from '../PillarPage/PillarPage';
import { Grid } from './components/Grid';
import { ProductContent } from './components/ProductContent';
import { APP_ROUTES } from '../../../constants/routes';
import { BusinessLayout } from '../../components/Layouts';

const isProd = process.env.NEXT_PUBLIC_APP_CONTEXT === 'production';

const Specialisatie: FC<PillarPageProps> = ({ strapiData, data }) => {
  const { attributes } = strapiData || {};
  const { seoMeta, hero, faq, testimonials, body, summary, services, category, filter, studentsListTitle } =
    attributes || {};
  const accordionData = faq?.items.map(({ question: header, answer: text, buildSchemaJson }) => ({
    header,
    text,
    buildSchemaJson,
  }));

  const signUpLink =
    category === 'city'
      ? `${APP_ROUTES.PARTNERS_SIGN_UP}?onboardType=jobFunction`
      : category === 'job'
      ? `${APP_ROUTES.PARTNERS_SIGN_UP}?onboardType=jobFunction&onboardValue=${encodeURIComponent(
          filter || ''
        )}`
      : undefined;

  return (
    <>
      <MetaTags
        title={seoMeta?.title}
        description={seoMeta?.description}
        canonicalUrl={isProd}
      />
      <BusinessLayout>
        {hero && <Frame930 title={hero?.title} subtitle={hero?.subtitle} link={signUpLink} />}
        <Services
          title={services?.titleServices}
          subtitle={services?.subTitle}
          bannerDesktop={services?.bannerDesktop.data?.attributes.url}
          bannerMobile={services?.bannerMobile.data?.attributes.url}
        />
        <Grid data={data} link={signUpLink} title={studentsListTitle} />
        <ProductContent summary={summary} body={body} link={signUpLink} />
        {!!faq && accordionData?.length && <FAQ title={faq.title} accordionData={accordionData} />}
        <Testimonials testimonials={testimonials} />
      </BusinessLayout>
    </>
  );
};

export default memo(Specialisatie);
