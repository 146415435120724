import { memo, useMemo } from 'react';
import { NextPage } from 'next';

import { APP_ROUTES } from 'constants/routes';
import Layout from 'features/layout/templates/Layout';
import { ContentWrapper } from 'src/common/ContentWrapper';
import { MetaTags } from 'src/common/Seo';
import { CheckOutJobs } from 'src/components/CheckOutJobs';
import { ReadyToJobCta } from 'src/components/JobCta/ReadyToJobCta';
import { ReadyToJobCtaLite } from 'src/components/JobCta/ReadyToJobCtaLite';
import * as types from 'api_entities/strapi/types';
import { wrapImageUrl } from 'api_entities/strapi/utils';
import { Summary } from 'src/components/Summary';
import { getBodyComponents } from 'src/components/Strapi';
import { Breadcrumb } from 'src/components/Breadcrumbs/types';
import { useBreadcrumbs } from 'src/components/Breadcrumbs/hooks';
import { StyledHeroBreadcrumbs } from 'src/components/Breadcrumbs';
import { VacanciesAdSection } from '../BlogArticle/components/VacanciesAdSection';
import { VacanciesAdSectionCards } from 'api_entities/strapi/types';

import { Hero } from './components/Hero';

import { ArticleBodyWrapper, ArticleWrapper, AsideWrapper, Content } from './PillarPageCore.styles';

const isProd = process.env.NEXT_PUBLIC_APP_CONTEXT === 'production';

export type PillarPageCoreProps = {
  strapiData: types.PillarPage;
  vacanciesAdSectionCards?: VacanciesAdSectionCards;
};

const PillarPageCore: NextPage<PillarPageCoreProps> = ({ strapiData, vacanciesAdSectionCards }) => {
  const { hero, seoMeta, body, asideTopBaner, asideBottomBaner, summary, author, updatedAt, slug } =
    strapiData?.attributes || {};

  const customBreadcrumbs: Breadcrumb[] = useMemo(() => {
    if (hero?.title) {
      return [{ href: `/${slug}`, label: hero.title }];
    } else {
      return [];
    }
  }, [hero?.title, slug]);
  const breadcrumbs = useBreadcrumbs(customBreadcrumbs);

  const seoImageUrl = seoMeta?.image?.data
    ? wrapImageUrl(seoMeta?.image?.data?.attributes?.formats?.medium?.url)
    : undefined;

  const link = {
    label: 'Studenten vacatures',
    href: APP_ROUTES.ALL_VACANCIES,
  };

  return (
    <>
      <MetaTags
        noDefaults
        canonicalUrl={isProd}
        title={seoMeta?.title}
        description={seoMeta?.description}
        keywords={seoMeta?.keywords}
        image={seoImageUrl}
        preventIndexing={seoMeta?.preventIndexing}
        ldJsonScheme={seoMeta?.scheme}
      />
      <Layout>
        <Hero
          title={hero?.title}
          subtitle={hero?.subtitle}
          lottie={hero?.lottieAnimation}
          dateOfPublication={hero?.dateOfPublication}
          updatedAt={updatedAt}
          readTime={hero?.readTime}
          author={author?.data}
          breadcrumbs={
            customBreadcrumbs?.length ? <StyledHeroBreadcrumbs items={breadcrumbs} /> : undefined
          }
        />
        {vacanciesAdSectionCards && (
          <ContentWrapper as="section">
            <VacanciesAdSection
              title={vacanciesAdSectionCards.title}
              vacancyAdCards={vacanciesAdSectionCards.vacancyAdCards}
            />
          </ContentWrapper>
        )}
        <ContentWrapper as="section">
          <Content>
            <ArticleWrapper>
              <div>{summary?.length ? <Summary title="Samenvatting" items={summary} /> : null}</div>
              <div>
                <ArticleBodyWrapper>{getBodyComponents(body)}</ArticleBodyWrapper>
              </div>
            </ArticleWrapper>
            <AsideWrapper>
              {asideTopBaner ? (
                <ReadyToJobCtaLite
                  title={asideTopBaner?.title}
                  subtitle={asideTopBaner?.description || ''}
                  link={asideTopBaner?.link}
                  shareText={asideTopBaner?.shareText}
                />
              ) : null}
              {asideBottomBaner ? (
                <ReadyToJobCta
                  title={asideBottomBaner?.title}
                  subtitle={asideBottomBaner?.description || ''}
                  link={asideBottomBaner?.link}
                  shareText={asideBottomBaner?.shareText}
                />
              ) : null}
            </AsideWrapper>
          </Content>
        </ContentWrapper>
        <CheckOutJobs title="Bijbanen voor studenten op iedere leeftijd" link={link} />
      </Layout>
    </>
  );
};

export default memo(PillarPageCore);
