import { APP_ROUTES } from 'constants/routes';

import { Breadcrumb } from './types';

export const BREADCRUMBS_MAP: Record<string, Breadcrumb> = {
  [APP_ROUTES.HOME]: {
    to: APP_ROUTES.HOME,
    name: 'Startpagina',
  },
  [APP_ROUTES.CITIES]: {
    to: APP_ROUTES.CITIES,
    name: 'Steden',
  },
  [APP_ROUTES.SEARCH]: {
    to: APP_ROUTES.SEARCH,
    name: 'Zoekresultaten',
  },
  [APP_ROUTES.JOBS]: {
    to: APP_ROUTES.JOBS,
    name: 'Banen',
  },
  [APP_ROUTES.STUDENT_SOCIETIES]: {
    to: APP_ROUTES.STUDENT_SOCIETIES,
    name: 'Studentenverenigingen',
  },
  [APP_ROUTES.STUDENT_HOUSING]: {
    to: APP_ROUTES.STUDENT_HOUSING,
    name: 'Studentenkamers',
  },
  [APP_ROUTES.LIFESTYLE]: {
    to: APP_ROUTES.LIFESTYLE,
    name: 'Studentenleven',
  },
  [APP_ROUTES.COMPANIES]: {
    to: APP_ROUTES.COMPANIES,
    name: 'Companies',
  },
  [APP_ROUTES.ALL_VACANCIES]: {
    to: APP_ROUTES.ALL_VACANCIES,
    name: 'Vacatures',
  },
};
