export const checkImgUrl = (src?: string): boolean => !!src?.startsWith('//images.ctfassets.net/');

type NormaliseSalary = (params: {
  salaryMin?: number;
  salaryMax?: number;
  salaryUnit?: string;
  salaryCurrency?: string;
}) => string;
export const normalizeSalary: NormaliseSalary = ({
  salaryMin,
  salaryMax,
  salaryUnit,
  salaryCurrency,
}): string => {
  if (!salaryUnit || !salaryMin || !salaryMax) {
    return '';
  }

  const salary = [salaryMin, salaryMax].join(' - ').replace(/\./gm, ',');
  const currency = salaryCurrency === 'EUR' ? '€' : '';

  return `${currency}${salary} per/${salaryUnit}`;
};
