import { device } from 'constants/device';

import styled from 'styled-components';

export const StyledBreadCrumbs = styled.nav`
  position: relative;
  top: 32px;
  max-width: 1240px;
  margin: auto;
  margin-bottom: 32px;
  display: block;
  font-size: 1.4rem;
  line-height: 1.21;
  font-weight: 500;
  color: ${({ theme }) => theme.brand.blue};
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${device.laptopL} {
    max-width: 925px;
  }
  @media ${device.laptop} {
    top: 16px;
    margin-bottom: 16px;
    max-width: calc(100% - 32px);
  }
`;

export const BreadcrumbLink = styled.a``;

export const BreadcrumbDivider = styled.span`
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  color: ${({ theme }) => theme.system.black};
  @media ${device.laptop} { 

    margin: 0 6px;
  }
`;
export const BreadcrumbCurrent = styled.span`
  opacity: 0.4;
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
`;
