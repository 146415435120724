import { FC, memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import slugify from 'slugify';
import { APP_ROUTES } from 'constants/routes';

import {
  Container,
  Content,
  PageNav,
  Samenvatting,
  SamenvattingList,
  TextContent,
  ContentWrapper,
  StickyNavbarContainer,
  GetStartedLink,
  LinkContainer,
} from './ProductContent.styled';
import { PageBody, SummaryItem } from '../../../../../api_entities/strapi/types';
import { getBodyComponentsBusiness } from '../../../../components/Strapi';

const slugifyOptions = {
  lower: true,
  strict: true,
  trim: true,
};

export type ProductContentProps = {
  body: PageBody;
  link?: string
  summary?: SummaryItem[];
};

const Layout: FC<ProductContentProps> = ({ body, summary, link }) => {
  return (
    <ContentWrapper>
      <Content>
        <TextContent>{getBodyComponentsBusiness(body)}</TextContent>
      </Content>
      <StickyNavbarContainer>
        {summary && summary.length !== 0 && (
          <Samenvatting>
            <SamenvattingList>
              {summary.map(({ id, title, headerText }) => {
                const elId = slugify(headerText, slugifyOptions);
                return (
                  <PageNav key={id} href={`#${elId}`}>
                    {title}
                  </PageNav>
                );
              })}
            </SamenvattingList>
            <LinkContainer>
              <Link passHref href={link || APP_ROUTES.PARTNERS_SIGN_UP} prefetch={false}>
                <GetStartedLink>
                  Start
                  <Image src="/assets/rightWhite.svg" alt="Arrow" width={24} height={24} />
                </GetStartedLink>
              </Link>
            </LinkContainer>
          </Samenvatting>
        )}
      </StickyNavbarContainer>
    </ContentWrapper>
  );
};

const ProductContent: FC<ProductContentProps> = ({ body, summary, link }) => {
  return (
    <Container>
      <Layout summary={summary} body={body} link={link} />
    </Container>
  );
};

export default memo(ProductContent);
