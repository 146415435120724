import React, { FC, memo } from 'react';
import Link from 'next/link';
import { APP_ROUTES } from 'constants/routes';
import { StudentCard } from 'api_entities/student/types';

import { Container, GridContainer, H1, GridWrapper, Button, ComponentWrapper } from './Grid.styled';
import { prepareStudentSlug } from '../../../../../api_entities/student/helpers';
import { InhurenStudentFeedCard } from '../../../../components/Cards/InhurenStudentFeed/InhurenStudentFeedCard';
import { getTagsFromStudent, getUsernameFromStudent } from '../../../../../utils/helpers';

export interface GridProps {
  title?: string;
  data?: {
    data: StudentCard[];
    meta: { count: number };
  };
  link?: string;
}

const Grid: FC<GridProps> = ({ data, link, title }) => {
  const studentCount = data?.meta.count ? data?.meta.count + 150 : 0;
  const _title = title ?? 'Studenten Direct Beschikbaar';

  return (
    <ComponentWrapper>
      <Container>
        <GridWrapper>
          <H1>
            {studentCount} {_title}
          </H1>
          <GridContainer>
            {data?.data.map((student) => {
              const slug = prepareStudentSlug(student);
              const href = `${APP_ROUTES.WERKGEVER}/${slug}`;

              return (
                <InhurenStudentFeedCard
                  key={student.objectID}
                  href={href}
                  imgSrc={student.profileImg}
                  username={getUsernameFromStudent(student)}
                  tags={getTagsFromStudent(student)}
                />
              );
            })}
          </GridContainer>
          <Link passHref href={link || APP_ROUTES.PARTNERS_SIGN_UP} prefetch={false}>
            <Button>Personeel Zoeken</Button>
          </Link>
        </GridWrapper>
      </Container>
    </ComponentWrapper>
  );
};

export default memo(Grid);
