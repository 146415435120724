import { FC, memo } from 'react';
import Link from 'next/link';

import { Breadcrumb } from './types';

export type BreadcrumbsBodyProps = {
  items?: Breadcrumb[];
  className?: string;
};

const Breadcrumbs: FC<BreadcrumbsBodyProps> = ({ items, className }) => {
  const itemsLength = items?.length;

  if (!itemsLength) {
    return null;
  }

  return (
    <nav className={className} aria-label="Breadcrumb">
      <ol itemScope itemType="https://schema.org/BreadcrumbList">
        {items?.map((crumb, idx) => {
          const isCurrent = idx === itemsLength - 1;

          return (
            <li
              key={idx}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <Link href={crumb.href}>
                <a
                  href={`${process.env.NEXT_PUBLIC_HOME_URL}${crumb.href}`}
                  aria-current={isCurrent ? 'page' : undefined}
                  itemProp="item"
                >
                  <span itemProp="name">{crumb.label}</span>
                  <meta itemProp="position" content={`${idx + 1}`} />
                </a>
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default memo(Breadcrumbs);
