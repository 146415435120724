import { device } from 'constants/device';

import styled from 'styled-components';

export const Main = styled.main`
  background-color: ${({ theme }) => theme.system.light};
  min-height: ${({ theme }) =>
    `calc(100vh - ${theme.v2.dimensions.footerHeight} - ${theme.v2.dimensions.headerHeight})`};
  position: relative;
  margin-top: ${({ theme }) => theme.v2.dimensions.headerHeight};
  @media ${device.laptop} {
    margin-top: ${({ theme }) => theme.v2.dimensions.mobileHeaderHeight};
    min-height: ${({ theme }) =>
      `calc(100vh - ${theme.v2.dimensions.mobileFooterHeight} - ${theme.v2.dimensions.mobileHeaderHeight})`};
  }
`;
