import { FC, memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {
  Banner,
  BannerButton,
  BannerContainer,
  BannerRow,
  Container,
  H1,
  BannerSubHeader,
} from './Services.styled';

export type ServicesProps = {
  title?: string;
  subtitle?: string;
  bannerDesktop?: string;
  bannerMobile?: string;
};

const Services: FC<ServicesProps> = ({ title, subtitle, bannerDesktop, bannerMobile }) => {
  return (
    <Container>
      <BannerContainer>
        <Banner bannerDesktop={bannerDesktop} bannerMobile={bannerMobile}>
          <H1>{title}</H1>
          <BannerSubHeader>{subtitle}</BannerSubHeader>
          <BannerRow>
            <Link href="/werving">
              <BannerButton>
                Werving & Selectie
                <Image src="/assets/right.svg" alt="Arrow" width={24} height={24} />
              </BannerButton>
            </Link>
            <Link href="/uitzendbureau">
              <BannerButton>
                Uitzenden
                <Image src="/assets/right.svg" alt="Arrow" width={24} height={24} />
              </BannerButton>
            </Link>
            <Link href="/jobboard">
              <BannerButton>
                Jobboard
                <Image src="/assets/right.svg" alt="Arrow" width={24} height={24} />
              </BannerButton>
            </Link>
          </BannerRow>
        </Banner>
      </BannerContainer>
    </Container>
  );
};

export default memo(Services);
