import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Content = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  margin: 16px 0 65px;

  ${up('lg')} {
    margin: 68px 0 85px;
  }

  ${up('xl')} {
    grid-template-columns: auto 288px;
  }
`;

export const ArticleWrapper = styled.div`
  display: grid;
  gap: 24px 48px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;

  ${up('lg')} {
    grid-template-columns: 264px auto;
  }
`;

export const ArticleBodyWrapper = styled.article`
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  & > div {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AsideWrapper = styled.div`
  & > * {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;
