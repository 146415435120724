import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  max-width: 100%;
  padding: 16px 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  ${down('md')} {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 808px;
  padding-bottom: 96px;
  margin: 0 auto;

  ${down('lg')} {
    padding: 0 16px 96px;
  }
`;

export const StickyNavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 392px;
  margin-left: 24px;
  ${down('lg')} {
    max-width: unset;
    margin: 0 16px;
  }
`;

export const TextContent = styled.div`
  color: #083344;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.span`
  color: #083344;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;

  ${down('md')} {
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
  }
`;

export const Text = styled.span`
  color: #083344;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  ${down('md')} {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
`;

export const Button = styled.a`
  width: fit-content;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.business.white};
  position: relative;
  display: flex;
  padding: 10px 32px;
  background-color: #1dbf70;
  border-radius: 8px;
  transition: all 0.1s ease-in;

  &:hover {
    background-color: #20d47c;
    color: #ffffff;
  }

  ${down('md')} {
    width: 100%;
    justify-content: center;
  }
`;

export const Samenvatting = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  margin: 64px auto 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: rgba(8, 51, 68, 0.1);
  position: sticky;
  top: 32px;
`;

export const PageNav = styled.a`
  color: #0e7490;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;

  &:hover {
    color: #0b5c73;
    text-decoration: underline;
  }
`;

export const SamenvattingList = styled.div`
  display: flex;
  flex-direction: column;
  list-style-type: decimal;
  gap: 16px;
  color: #0e7490;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;

export const Blur = styled.div`
  width: 512px;
  height: 512px;
  position: absolute;
  top: -135px;
  left: calc(50% - 256px);
  display: block;
  border-radius: 512px;
  opacity: 0.15000000596046448;
  background: #fff;
  filter: blur(150px);
  overflow-x: hidden;
  max-width: 100vw;
`;

export const NavLink = styled.a<{ isDark?: boolean }>`
  display: flex;
  gap: 9px;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.business.white};
  transition: color 0.1s ease-in;

  img {
    vertical-align: middle;
  }
`;

export const GetStartedLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  background-color: #1dbf70;
  border-radius: 8px;
  transition: background-color 0.1s ease-in;
  margin: 24px auto 0;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  line-height: 130%;

  &:hover {
    background-color: #20d47c;
  }

  ${down('md')} {
    width: 100%;
    justify-content: center;
    font-size: 16px;
    line-height: 130%;
  }
`;

export const LinkContainer = styled.div`
  width: 100%;
  ${down('md')} {
    display: none;
  }
`;
