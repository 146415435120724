import { FC, memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { APP_ROUTES } from 'constants/routes';

import {
  Container,
  Title,
  TextBlock,
  BannerText,
  Content,
  Blur,
  GetStartedLink,
} from './Frame930.styled';

export type HeroProps = {
  title?: string;
  subtitle?: string;
  link?: string;
};

const Frame930: FC<HeroProps> = ({ title, subtitle, link }) => {
  return (
    <Container>
      <Blur />
      <Content>
        <TextBlock>
          <Title>{title}</Title>
          <BannerText>{subtitle}</BannerText>
          <Link passHref href={link || APP_ROUTES.PARTNERS_SIGN_UP} prefetch={false}>
            <GetStartedLink>
              Start
              <Image src="/assets/rightWhite.svg" alt="Arrow" width={24} height={24} />
            </GetStartedLink>
          </Link>
        </TextBlock>
      </Content>
    </Container>
  );
};

export default memo(Frame930);
